




































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import config from '@/config';

@Component({})
export default class MemberMe extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  private apikeys: any = [];
  private orgapikeys: any = [];
  private selectedKey: any = {};
  private selectedKeyIsOrg: boolean = false;
  private showSelectedKey: boolean = false;
  private showCaslConfiguration: boolean = false;
  private availableScopes: any = [];
  private selectedKeyScopes: any = [];
  private loading: boolean = true;

  public async created() {
    this.refresh();
  }

  @Watch('selectedKeyScopes')
  private saveCaslScope(val: any) {
    if (this.showCaslConfiguration) {
      axios.patch(`/organisation/apikey/${this.selectedKey.id}`, {
        scopes: val,
      }).then((response: any) => {
        const apikey = response.data;
        this.orgapikeys.find((k: any) => k.id === apikey.id).scopes = apikey.scopes;
      }).catch((error: any) => {
        this.globalError(error);
      });
    }
  }

  @Watch('$route')
  private refresh() {
    this.loading = true;

    this.$store.dispatch('loadMemberApiKeys')
    .then((response: any) => {
      this.apikeys = response;
      this.loading = false;
    }).catch((error: any) => {
      this.globalError(error);
    });

    if (this.$can('manage', 'organisation-apikey')) {
      this.$apiget(`/organisation/apikeys`)
      .then((response: any) => {
        this.orgapikeys = response;
      }).catch((error: any) => {
        this.globalError(error);
      });
    }
  }

  private showKey(apikey: any, isOrgKey: boolean) {
    this.selectedKey = apikey;
    this.selectedKeyIsOrg = isOrgKey;
    this.showSelectedKey = true;
  }

  private configureScope(apikey: any) {
    if (this.availableScopes.length === 0) {
      this.$apiget(`/organisation/scopes`)
      .then((response: any) => {
        this.availableScopes = response.scopes;
      }).catch((error: any) => {
        this.globalError(error);
      });
    }
    this.selectedKey = apikey;
    this.selectedKeyScopes = apikey.scopes;
    this.$nextTick(() => {
      this.showCaslConfiguration = true;
    });
  }

  private deleteSelectedKey() {
    const name = this.selectedKey.name;
    this.$q.dialog({
      title:  this.$t('api_delete_title'),
      message: this.$t('api_delete_msg') + ' "' + name + '"',
      ok: {
        label: this.$t('api_delete_confirm'),
        outline: true,
        color: 'cctext',
      },
      cancel: {
        label: this.$t('api_delete_cancel'),
        flat: true,
        color: 'cctext',
      },
    }).onOk(() => {
      this.loading = true;
      let future;
      if (this.selectedKeyIsOrg) {
        future = axios.delete(`${config.api_url}/organisation/apikey/${this.selectedKey.id}`);
      } else {
        future = axios.delete(`${config.api_url}/member/apikey/${this.selectedKey.id}`);
      }
      future.then((response: any) => {
        this.showSelectedKey = false;
        this.selectedKey = {};
        this.refresh();
      }).catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
    });
  }

  private createApiKey() {
    this.$q.dialog({
      title:  this.$t('api_create_title'),
      message:  this.$t('api_create_msg'),
      prompt: {
        model: '',
        type: 'text',
      },
      ok: {
        outline: true,
        color: 'cctext',
      },
      cancel: {
        flat: true,
        color: 'cctext',
      },
    }).onOk((data: any) => {

      if (data.length < 3) {
        this.$q.dialog({
          title: this.$t('api_create_error1_title'),
          message: this.$t('api_create_error1_msg'),
        });
        return;
      }

      this.loading = true;
      this.$store.dispatch('createMemberApiKey', {
        payload: {
          name: data,
        },
      })
      .then((response: any) => {
        this.refresh();
        this.showKey(response, false);
      }).catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
    });
  }


  private createOrgApiKey() {
    this.$q.dialog({
      title:  this.$t('api_create_title'),
      message:  this.$t('api_create_msg'),
      prompt: {
        model: '',
        type: 'text',
      },
      ok: {
        outline: true,
        color: 'cctext',
      },
      cancel: {
        flat: true,
        color: 'cctext',
      },
    }).onOk((data: any) => {
      if (data.length < 3) {
        this.$q.dialog({
          title: this.$t('api_create_error1_title'),
          message: this.$t('api_create_error1_msg'),
        });
        return;
      }

      this.loading = true;
      axios.post(`${config.api_url}/organisation/apikeys`, {
        name: data,
      }).then((response: any) => {
        this.refresh();
        this.showKey(response.data, true);
      }).catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
    });
  }
}
